export const calculatePriceAfterPromoCodeAndDTP = ({
  extendedListPrice,
  discounts,
}: {
  extendedListPrice: number,
  discounts: Array<{ discountedAmount: number }> | { coupon: number } | undefined,
}) => {
  let couponDiscountTotal = 0
  if (Array.isArray(discounts)) {
    couponDiscountTotal = discounts?.reduce?.((couponSum, coupon) => (
      couponSum + coupon.discountedAmount
    ), 0)
  } else {
    couponDiscountTotal = discounts?.coupon || 0
  }
  const price = extendedListPrice - couponDiscountTotal
  const rounded = Math.round((price + Number.EPSILON) * 100) / 100 // EPSILON ensures accuracy
  return rounded
}

export default calculatePriceAfterPromoCodeAndDTP
