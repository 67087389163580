import { error, log } from '@/services/Log'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { getOrder } from '@/services/FramedCheckout/orderServices/getOrder'
import { getCart } from '@/services/FramedCheckout/orderServices/getCart'
import { onCheckoutBegin } from '@/services/Tracking/Analytics/onCheckoutBegin'
import type {
  StandardAddress,
  StandardCart,
  StandardOrder,
  StandardShipment,
} from '@/types/ShopFront/CheckoutStandards'
import type { StandardCustomer } from '@/types/ShopFront/StandardCustomer'
import { SavedInstrument } from '@/types'

const getModules = () => allPromisesWithRetries(() => [
  import('@/services/FramedCheckout/navigationServices'),
  import('@/services/FramedCheckout/customerServices/getCustomer'),
  import('@/services/FramedCheckout/shipmentServices/updateShippingAddress'),
  import('@/helpers/addressHelpers'),
  import('@/services/FramedCheckout/paymentServices/getPaymentMethods'),
  import('@/services/FramedCheckout/loyaltyServices'),
  import('@/services/FramedCheckout/orderServices/recreateOrder'),
  import('@/helpers/graphql'),
])
  .then(([
    { goToCart },
    { getCustomer },
    {
      createNonBopusConsignments,
      isOrderUpdatePending,
    },
    { isValidAddress },
    { getPaymentMethods },
    { removeStoreCredit },
    { recreateOrder },
  ]) => ({
    goToCart,
    getCustomer,
    createNonBopusConsignments,
    isOrderUpdatePending,
    isValidAddress,
    getPaymentMethods,
    removeStoreCredit,
    recreateOrder,
  }))

const shipmentFailedToProvideOptions = (isValidAddress: (StandardAddress) => boolean) => ({
  availableShippingOptions,
  shippingAddress,
}: StandardShipment) => !availableShippingOptions.length && !!isValidAddress(shippingAddress)
const initializeOrder = async ({ order }: {
  order: StandardOrder
}) => {
  const {
    createNonBopusConsignments,
    isOrderUpdatePending,
    isValidAddress,
    removeStoreCredit,
    recreateOrder,
  } = await getModules()

  let newOrder: StandardOrder = { ...order }
  // DETECT CACHE FAILURE BY LOOKING AT SHIPMENT ADDRESSES AND AVAILABLE SHIPPING OPTIONS
  if (order.isStoreCreditApplied) {
    const removeStoreCreditResponse = await removeStoreCredit(newOrder)
    if (removeStoreCreditResponse.success && removeStoreCreditResponse.newOrder) {
      newOrder = removeStoreCreditResponse.newOrder
    }
  }

  const bigcommerceHasFailedShippingOptionsCache = (
    !!newOrder.shipments
    && !!newOrder.shipments.find(shipmentFailedToProvideOptions(isValidAddress))
  )
  if (bigcommerceHasFailedShippingOptionsCache) {
    log('bigcommerce Has Failed Shipping Options Cache')
    const recoveredOrder = await recreateOrder({ order: newOrder })
    if (recoveredOrder.success && recoveredOrder.newOrder) {
      newOrder = recoveredOrder.newOrder
    }
  }

  const shouldSplitFurnitureShipment = !!newOrder.shipments.find(({
    isBopus,
    lineItems,
  }) => (
    !isBopus
    && !!lineItems.find(({ isFurniture }) => isFurniture)
    && !!lineItems.find(({ isFurniture }) => !isFurniture)
  ))
  const usedAddress = newOrder?.shipments?.find?.(({ isBopus }) => !isBopus)?.shippingAddress
  if (
    usedAddress && (
      shouldSplitFurnitureShipment
      || isOrderUpdatePending({
        order: newOrder,
        address: usedAddress,
      })
    )
  ) {
    const response = await createNonBopusConsignments({
      order: newOrder,
      address: usedAddress,
    })
    newOrder = response.newOrder
    log('Split Furniture Shipment', { newOrder })
  }
  // Should check for multiple BOPUS shipments for the same store
  return newOrder
}
type SuccessfullInitializeCheckoutReturnType = {
  success: true,
  cart: StandardCart,
  order: StandardOrder,
  customer: StandardCustomer | null,
  addresses: StandardAddress[],
  savedInstruments: SavedInstrument[],
}
type FailedInitializeCheckoutReturnType = {
  success: false,
  cart: StandardCart | null,
  order: StandardOrder | null,
  customer: StandardCustomer | null,
  addresses: null,
  savedInstruments: null,
}
type InitializeCheckout = () => (
  Promise<SuccessfullInitializeCheckoutReturnType | FailedInitializeCheckoutReturnType>
)
const failuredResponse: FailedInitializeCheckoutReturnType = {
  success: false,
  cart: null,
  order: null,
  customer: null,
  addresses: null,
  savedInstruments: null,
}
export const initializeCheckout :InitializeCheckout = async () => {
  log('initializeCheckout Start')
  const {
    goToCart,
    getCustomer,
    getPaymentMethods,
    recreateOrder,
  } = await getModules()
  let addresses: StandardAddress[] = []
  let savedInstruments: SavedInstrument[] = []
  let order: StandardOrder
  let cart: StandardCart | null = null
  let customer: StandardCustomer | null = null
  try {
    try {
      cart = await getCart()
    } catch {
      throw new Error('Failed to get cart')
    }
    if (!cart) {
      await goToCart()
      return failuredResponse
    }
    try {
      order = await initializeOrder({
        order: await getOrder({ cart }),
      })
    } catch {
      throw new Error('Failed to initializeOrder')
    }
    try {
      customer = await getCustomer()
      if (customer?.email && order?.cart && !order.cart.email) {
        const recoveredOrder = await recreateOrder({ order })
        if (recoveredOrder.success && recoveredOrder.newOrder && recoveredOrder.newOrder.cart) {
          order = recoveredOrder.newOrder
          order.email = order.cart.email
          order.customerId = parseInt(String(order.customerId || customer.id || 0), 10)
          cart = order?.cart
        }
      }
    } catch (err) {
      error(`Failed to retrieve customer ${err}`)
    }
    if (customer) {
      try {
        addresses = customer?.addresses
      } catch {
        error('Failed to retrieve addresses')
      }
      try {
        savedInstruments = (await getPaymentMethods(String(customer.email || '')))?.savedInstruments
      } catch {
        error('Failed to retrieve savedInstruments')
      }
    }
    // Analytics
    try {
      onCheckoutBegin({
        cart,
        user: customer || undefined,
      })
    } catch (e) {
      error('initializeCheckout:: Failed to send analytic')
    }
    log('initializeCheckout End', {
      success: true, cart, order, customer, addresses, savedInstruments,
    })
    return {
      success: true, cart, order, customer, addresses, savedInstruments,
    }
  } catch (err) {
    error(`initializeCheckout FAILED:\t ${err}`)
    return initializeCheckout()
  }
}

export default initializeCheckout
