import { handleTildedField } from '@/helpers/handleTildedField'
import { capitalizeTokens } from '@/helpers/capitalizeTokens'

const isRelevantOptionForName = ({ value } : { value: string }) => `${value}`.toLowerCase().trim() !== 'none'
const optionToName = ({ value } : { value: string }) => capitalizeTokens(handleTildedField(value))
export const standardizeProductName = (name: string, options: readonly { value: string }[]) => {
  const relevantOptionsForName = (
    Array.isArray(options) && options.length
      ? options.filter(isRelevantOptionForName)
      : []
  )
  return (
    `${handleTildedField(name)} ${(
      relevantOptionsForName.length
        ? `- ${relevantOptionsForName?.map(optionToName).join(', ')}`
        : ''
    )}`
  ).trim()
}

export default standardizeProductName
