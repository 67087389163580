import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { GA4_GROUP } from '@/data/constants'
import type { StandardCart } from '@/types/ShopFront/CheckoutStandards'
import { isExtendSku } from '@/helpers/checkIsExtendItem'
import { checkIsShippedShieldSku } from '@/helpers/checkIsShippedShieldItem'
import { FEATURE_TOGGLES } from '@/services/Configuration'

const {
  facebookConversionApiEnabled: FACEBOOK_CONVERSION_TOGGLE,
} = FEATURE_TOGGLES
export const onCheckoutBegin = async ({ cart, user }: {
  cart: StandardCart,
  user?: { email: string, phone: string }
}) => {
  const [
    {
      trackTikTokPixel,
      identifyCustomerTikTokPixel,
    },
    { convertProductToGA4Item },
    { trackEvent },
    { Facebook },
    { bySku },
  ] = await allPromisesWithRetries(() => [
    import('@/services/TikTokPixel'),
    import('@/services/Tracking/GTAG/convertProductToGA4Item'),
    import('@/services/Tracking/GTAG/trackEvent'),
    import('@/services/Tracking/Facebook/Facebook'),
    import('@/services/Product/bySku'),
  ])

  // TODO: Rewrite with types, Modify bySKU
  const lineItemsWithCategories = await Promise.all(
    cart.lineItems.map(async (lineItem) => {
      if (isExtendSku(lineItem.sku) || checkIsShippedShieldSku(lineItem.sku)) {
        return {
          ...lineItem,
          categories: [],
          category: 'N/A',
          price: lineItem.salePrice,
          variantId: `${lineItem.variantId}`,
        }
      }
      const [product] = await bySku({ sku: lineItem.sku })
      return {
        ...lineItem,
        categories: product.categories,
        category: product.category_info?.[0].name,
        price: product.salePrice,
        variantId: `${lineItem.variantId}`,
        name: lineItem.originalName ?? lineItem.name,
      }
    }),
  )

  const convertedItems = lineItemsWithCategories.map((
    {
      name,
      sku,
      originalPrice,
      salePrice,
      quantity,
      variantId,
      brand,
      categories,
    }, index,
  ) => convertProductToGA4Item({
    name,
    sku,
    brand,
    categories,
    price: originalPrice,
    salePrice,
    variantId: String(variantId),
    category: undefined,
    position: index,
    quantity,
  }))

  // GA4
  trackEvent('begin_checkout', {
    currency: 'USD',
    value: cart.subtotal,
    items: convertedItems,
    user,
  }, GA4_GROUP)

  // TikTok
  if (user) {
    identifyCustomerTikTokPixel(user.email, user.phone)
  }
  trackTikTokPixel('InitiateCheckout')

  if (FACEBOOK_CONVERSION_TOGGLE) {
    const initializeCheckoutPayload = {
      user,
      content_ids: (
        lineItemsWithCategories.map(({ sku }) => String(sku || ''))
      ),
      contents: cart.lineItems.map(({ sku, quantity, salePrice }) => ({
        id: sku, quantity, item_price: salePrice,
      })),
      value: cart.subtotal,
      num_items: cart.lineItems.reduce((total, { quantity }) => (
        total + quantity
      ), 0),
    }
    Facebook.initializeCheckout(initializeCheckoutPayload)
  }
}

export default onCheckoutBegin
