import { calculatePriceAfterPromoCodeAndDTP } from '@/helpers/checkoutHelpers/calculatePriceAfterPromoCodeAndDTP'
import { error } from '@/services/Log'
import type { PropagatedLineItemFields, StandardLineItem } from '@/types/ShopFront/CheckoutStandards'
import { standardizeProductName } from '@/services/Standardizers/checkout/standardizeProductName'
import { standardizeProductOption } from '@/services/Standardizers/checkout/standardizeProductOption'

const pdpPath = /^\/p([a-z0-9-]+)$/
export const generateRelativeUrl = (urlToTransform: string) => {
  let newURL = urlToTransform
  try {
    const baseUrl = new URL(window.location.href).origin
    const currentPath = (
      pdpPath.test(urlToTransform)
        ? urlToTransform
        : new URL(urlToTransform).pathname
    )
    newURL = baseUrl + currentPath
  } catch (e) {
    error(`Failed to generate Relative URL: ${String(e)}`, {
      urlToTransform,
    })
  }
  return newURL
}

type IncompleteOrderLineItemDiscounts = readonly {
  discountedAmount: number
  id: string | number
}[]

type PendingOrderLineItemDiscounts = Record<number, number>
type Itemdiscounts = IncompleteOrderLineItemDiscounts | PendingOrderLineItemDiscounts
type StandardCoupons = Array<{ id: string, discountedAmount: number }>

export type LineItemStandardizerInput = {
  name: string,
  options: Array<{
    name: string
    value: string
    // nameId: number
    // valueId: number
  }>
  extendedListPrice: number,
  discounts: Itemdiscounts
} & PropagatedLineItemFields

export type LineItemStandardizer = (input: LineItemStandardizerInput) => StandardLineItem

export const standardizeLineItemDisocuntMapFromBCPendingOrder = (
  discounts: PendingOrderLineItemDiscounts,
): StandardCoupons => [
  ...Object.entries(discounts || {}).map(([id, discountedAmount]) => ({
    discountedAmount,
    id,
  })),
]

export const standardizeLineItemDisocuntMapFromBCCart = (
  discounts: IncompleteOrderLineItemDiscounts,
): StandardCoupons => [...discounts || []].map(({
  discountedAmount,
  id,
}) => ({
  discountedAmount,
  id: String(id),
}))

const isPendingOrderDiscounts = (
  discounts: Itemdiscounts,
): discounts is PendingOrderLineItemDiscounts => (
  !Array.isArray(discounts)
)

const standardizeNoNullDiscounts = (discounts: Itemdiscounts): StandardCoupons => (
  isPendingOrderDiscounts(discounts)
    ? standardizeLineItemDisocuntMapFromBCPendingOrder(discounts)
    : standardizeLineItemDisocuntMapFromBCCart(discounts)
)

export const standardizeLineItemDiscounts = (discounts?: Itemdiscounts): StandardCoupons => (
  discounts
    ? standardizeNoNullDiscounts(discounts)
    : []
)

export const standardizeLineItem: LineItemStandardizer = ({
  name,
  options = [],
  discounts,
  extendedListPrice,
  listPrice,
  url,
  ...rest
}): StandardLineItem => ({
  name: standardizeProductName(name, options),
  originalName: name,
  options: options?.map(standardizeProductOption),
  url: url ? generateRelativeUrl(url) : '',
  salePriceAfterDiscount: calculatePriceAfterPromoCodeAndDTP({
    extendedListPrice,
    discounts: standardizeLineItemDiscounts(discounts),
  }),
  subtotalPrice: listPrice,
  listPrice,
  extendedListPrice,
  discounts: standardizeLineItemDiscounts(discounts),
  ...rest,
})

export default standardizeLineItem
