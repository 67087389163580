import capitalizeTokens from '@/helpers/capitalizeTokens'
import handleTildedField from './handleTildedField'

export type MinimalOptionsProps = {
  name: string,
  nameId: number
  value: string,
  valueId: number,
}

export const standardizeProductOption = ({
  name,
  nameId,
  value,
  valueId,
}: MinimalOptionsProps) => ({
  name,
  nameId,
  value: capitalizeTokens(handleTildedField(value)),
  valueId,
})

export default standardizeProductOption
