import { standardizeLineItem } from '@/services/Standardizers/checkout/standardizeLineItem'
import type { StandardCart } from '@/types/ShopFront/CheckoutStandards'

type CartStandardizerInput = {
  lineItems: unknown[],
  baseAmount: number,
  cartAmount: number,
  discountAmount: number,
  customerId: number,
  email: string,
  id: string,
  updatedTime: string,
  coupons: readonly {
    id: number,
    code: string,
    displayName: string,
    couponType?: string,
    discountedAmount: number,
  }[]
}
export type CartStandardizer = (input: CartStandardizerInput) => StandardCart

export type MinCouponType = {
  couponType?: string,
}
export const guaranteeCouponType = <T extends MinCouponType>(
  coupon: T,
): T & { couponType: string } => ({
    ...coupon,
    couponType: coupon.couponType || '',
  })

export const standardizeCartCoupons = <T extends MinCouponType>(coupons?: readonly T[]) => (
  [...coupons || []].map(guaranteeCouponType)
)

export const standardizeCart: CartStandardizer = ({
  lineItems,
  baseAmount,
  cartAmount,
  coupons,
  customerId,
  discountAmount,
  email = '',
  id,
  updatedTime,
}): StandardCart => ({
  lineItems: lineItems.map(standardizeLineItem),
  subtotal: baseAmount,
  total: cartAmount,
  discount: discountAmount,
  customerId,
  email,
  id: String(id || ''),
  updatedTime,
  coupons: standardizeCartCoupons(coupons),
})

export default standardizeCart
